import * as React from 'react'

import Page from '../../../components/Page'
import Container from '../../../components/Container'
import IndexLayout from '../../../layouts'

const PaymentSuccessPage = () => (
  <IndexLayout>
    <Page>
      <Container>
        <h2>Die Bezahlung wurde abgebrochen.</h2>
        <p>
          Wenden Sie sich bitte an <a href="mailto:sams@samshofbauer.at">sams@samshofbauer.at</a>
        </p>
      </Container>
    </Page>
  </IndexLayout>
)

export default PaymentSuccessPage
